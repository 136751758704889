import dayjs from "dayjs";

import { saveAs } from "file-saver";

export const getUserName = (userData) => {
    if (!userData) {
        return "";
    }
    let userName = "";
    const { first_name, last_name, email } = userData;
    if (first_name || last_name) {
        if (first_name) {
            userName += first_name;
        }
        if (last_name) {
            userName += userName ? ` ${last_name}` : last_name;
        }
    }
    if (userName) {
        return userName;
    }
    if (email) {
        userName += email;
    }
    if (userName) {
        return userName;
    }
    return "";
};

export const getErrorMessage = (e) => {
    if (e.message === "Network Error") {
        return "El backend está ahora en modo de mantenimiento. Vuelva a intentarlo en unos minutos.";
    } else {
        if (e.response) {
            if (e.response.status === 401) {
                return "¡No estás autenticado, inicia sesión nuevamente!";
            } else if (e.response.status === 403) {
                return "¡No tienes permiso!";
            } else if (e.response.status === 404) {
                return "URL no encontrada";
            } else if (e.response.status === 405) {
                return "Solicitud incorrecta";
            } else if (e.response.status === 500) {
                return "Error de API";
            } else {
                if (e.response?.data?.message) {
                    return e.response.data.message;
                } else if (e.response?.data?.detail) {
                    return e.response.data.detail;
                } else {
                    return "Solicitud incorrecta";
                }
            }
        } else {
            return "Backend is in maintenance mode now, please try again in a few minutes.";
        }
    }
};

export const formatDateTime = (datetime, formatter = "DD/MM/YYYY HH:mm") => {
    if (!datetime) return "";
    return dayjs(datetime).format(formatter);
};

export const filterTextHandler = (text, searchKey) => {
    if (!text) return false;
    return (
        text
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
                searchKey
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
            ) !== -1
    );
};

export const filterSelectOptionHandler = (input, option) => filterTextHandler(option.children, input);

export const fileToBase64Handler = (file) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        };
    });
};

export const downloadFileHandler = (url, filename) => saveAs(url, filename);
