import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { rgba } from "polished";

import { Card as AntdCard } from "antd";

const props = {
    children: PropTypes.any,
    className: PropTypes.string,

    border: PropTypes.string,
    shadow: PropTypes.bool,
    padding: PropTypes.number,
};

const Card = ({ children, className, border, shadow, padding = 24 }) => {
    return (
        <Wrapper className={className} border={border} shadow={(shadow || true).toString()} padding={padding}>
            {children}
        </Wrapper>
    );
};

const Wrapper = styled(AntdCard)`
    border: ${(props) => (props.border ? `1px solid ${props.border}` : "none")};
    box-shadow: ${(props) => (props.shadow === "true" ? `0 5px 20px ${rgba(props.theme.colorBlack, 0.08)}` : "none")};

    overflow: visible;

    background-color: ${(props) => props.theme.colorWhite};

    .ant-card-body {
        padding: ${(props) => (props.padding !== undefined ? props.padding : "24")}px;

        &::before,
        &::after {
            display: none;
        }
    }
`;

Card.propTypes = props;

export default Card;
