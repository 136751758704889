import * as actionTypes from "../actionTypes";

import { updateState } from "../utils";

const initialState = {
    categories: null,
    activities: null,
    total: 0,
    filter: {
        page: 1,
        searchKey: "",
        category: null,
    },
};

const updateCategories = (state, action) => {
    return updateState(state, {
        categories: action.categories,
    });
};

const updateActivities = (state, action) => {
    return updateState(state, {
        activities: action.activities,
        total: action.total,
    });
};

const updateFilter = (state, action) => {
    return updateState(state, {
        filter: action.filter,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ACTIVITY_CATEGORIES:
            return updateCategories(state, action);
        case actionTypes.UPDATE_ACTIVITIES:
            return updateActivities(state, action);
        case actionTypes.UPDATE_ACTIVITY_FILTER:
            return updateFilter(state, action);
        default:
            return state;
    }
};

export default reducer;
