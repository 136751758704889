export const UPDATE_SIDER_COLLAPSED = "UPDATE_SIDER_COLLAPSED";
export const UPDATE_LOADING = "UPDATE_LOADING";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";

export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const UPDATE_SOCKET_TOKEN = "UPDATE_SOCKET_TOKEN";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";

export const UPDATE_COMMUNES = "UPDATE_COMMUNES";
export const UPDATE_ECONOMIC_SECTORS = "UPDATE_ECONOMIC_SECTORS";
export const UPDATE_COMPANY_TYPES = "UPDATE_COMPANY_TYPES";

export const UPDATE_ALL_USERS = "UPDATE_ALL_USERS";
export const UPDATE_USERS = "UPDATE_USERS";
export const UPDATE_USER_FILTER = "UPDATE_USER_FILTER";

export const UPDATE_ASSOCIATION_TYPES = "UPDATE_ASSOCIATION_TYPES";
export const UPDATE_JOB_AREAS = "UPDATE_JOB_AREAS";
export const UPDATE_POSITION_CLASSES = "UPDATE_POSITION_CLASSES";
export const UPDATE_JOB_ROLES = "UPDATE_JOB_ROLES";
export const UPDATE_JOB_ROLE_CATEGORIES = "UPDATE_JOB_ROLE_CATEGORIES";
export const UPDATE_COMPANIES = "UPDATE_COMPANIES";
export const UPDATE_COMPANY_FILTER = "UPDATE_COMPANY_FILTER";

export const UPDATE_ALL_PERSONS = "UPDATE_ALL_PERSONS";
export const UPDATE_PERSONS = "UPDATE_PERSONS";
export const UPDATE_PERSON_FILTER = "UPDATE_PERSON_FILTER";
export const UPDATE_INTERESTS = "UPDATE_INTERESTS";

export const UPDATE_ALL_EXECUTIVES = "UPDATE_ALL_EXECUTIVES";
export const UPDATE_EXECUTIVES = "UPDATE_EXECUTIVES";
export const UPDATE_EXECUTIVE_FILTER = "UPDATE_EXECUTIVE_FILTER";

export const UPDATE_ACTIVITY_CATEGORIES = "UPDATE_ACTIVITY_CATEGORIES";
export const UPDATE_ACTIVITIES = "UPDATE_ACTIVITIES";
export const UPDATE_ACTIVITY_FILTER = "UPDATE_ACTIVITY_FILTER";

export const UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS";
export const UPDATE_GROUPS = "UPDATE_GROUPS";
export const UPDATE_GROUP_FILTER = "UPDATE_GROUP_FILTER";

export const UPDATE_PARTNERSHIPS = "UPDATE_PARTNERSHIPS";

export const UPDATE_DASHBOARD_TOTAL_DATA = "UPDATE_DASHBOARD_TOTAL_DATA";
export const UPDATE_DASHBOARD_COMPANIES = "UPDATE_DASHBOARD_COMPANIES";
export const UPDATE_DASHBOARD_PERSONS = "UPDATE_DASHBOARD_PERSONS";
export const UPDATE_DASHBOARD_ACTIVITIES = "UPDATE_DASHBOARD_ACTIVITIES";
