import * as actionTypes from "../actionTypes";

import { updateState } from "../utils";

const initialState = {
    associationTypes: null,
    jobAreas: null,
    positionClasses: null,
    jobRoles: null,
    jobRoleCategories: null,
    companies: null,
    total: 0,
    filter: {
        page: 1,
        searchKey: "",
        status: null,
        companyType: null,
        economicSector: null,
        employeeRange: null,
        benefit: null,
        partnership: null,
        sortKey: "created_at",
        sortDirection: "desc",
    },
};

const updateAssociationTypes = (state, action) => {
    return updateState(state, {
        associationTypes: action.associationTypes,
    });
};

const updateJobAreas = (state, action) => {
    return updateState(state, {
        jobAreas: action.jobAreas,
    });
};

const updatePositionClasses = (state, action) => {
    return updateState(state, {
        positionClasses: action.positionClasses,
    });
};

const updateJobRoles = (state, action) => {
    return updateState(state, {
        jobRoles: action.jobRoles,
    });
};

const updateJobRoleCategories = (state, action) => {
    return updateState(state, {
        jobRoleCategories: action.jobRoleCategories,
    });
};

const updateCompanies = (state, action) => {
    return updateState(state, {
        companies: action.companies,
        total: action.total,
    });
};

const updateFilter = (state, action) => {
    return updateState(state, {
        filter: action.filter,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ASSOCIATION_TYPES:
            return updateAssociationTypes(state, action);
        case actionTypes.UPDATE_JOB_AREAS:
            return updateJobAreas(state, action);
        case actionTypes.UPDATE_POSITION_CLASSES:
            return updatePositionClasses(state, action);
        case actionTypes.UPDATE_JOB_ROLES:
            return updateJobRoles(state, action);
        case actionTypes.UPDATE_JOB_ROLE_CATEGORIES:
            return updateJobRoleCategories(state, action);
        case actionTypes.UPDATE_COMPANIES:
            return updateCompanies(state, action);
        case actionTypes.UPDATE_COMPANY_FILTER:
            return updateFilter(state, action);
        default:
            return state;
    }
};

export default reducer;
