import { combineReducers } from "redux";

import theme from "./theme";
import auth from "./auth";
import dashboard from "./dashboard";
import data from "./data";
import user from "./user";
import company from "./company";
import person from "./person";
import activity from "./activity";
import payment from "./payment";
import security from "./security";

export default combineReducers({
    theme,
    auth,
    dashboard,
    data,
    user,
    company,
    person,
    activity,
    payment,
    security,
});
