/** ------------------- */
// ENV VARIABLES
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const SOCKET_BASE_URL = process.env.REACT_APP_SOCKET_BASE_URL;

/** ------------------- */
// COLORS
export const colorWhite = "#FFF";
export const colorGray = "#707070";
export const colorDark = "#212121";
export const colorBlack = "#000";

export const colorPrimary = "#DC3447";
export const colorError = "#F44336";
export const colorWarning = "#FF9800";
export const colorYellow = "#FFEB3B";
export const colorSuccess = "#0CA034";
export const colorBlue = "#6382EB";
export const colorPurple = "purple";

/** ------------------- */
// LOCALSTORAGE KEYS
export const LS_VERIFY_EMAIL_KEY = "verify_email";
export const LS_AUTH_TOKEN_KEY = "auth_token";

/** ------------------- */
// STRING MESSAGES
export const msgFieldRequired = "Este campo es obligatorio";
export const msgFieldInvalid = "Este campo no es válido";
export const msgConfirmPasswordCorrectly = "Confirmar contraseña correctamente";
export const msgConfirmExcelExport = "EXCEL EXPORT MESSAGE";

/** ------------------- */
// SYSTEM CONSTANTS
