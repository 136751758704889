import * as actionTypes from "../actionTypes";
import { updateState } from "../utils";

const initialState = {
    communes: null,
    economicSectors: null,
    companyTypes: null,
    partnerships: null,
};

const updateCommunes = (state, action) => {
    return updateState(state, {
        communes: action.communes,
    });
};

const updateEconomicSectors = (state, action) => {
    return updateState(state, {
        economicSectors: action.economicSectors,
    });
};

const updateCompanyTypes = (state, action) => {
    return updateState(state, {
        companyTypes: action.companyTypes,
    });
};

const updatePartnerships = (state, action) => {
    return updateState(state, {
        partnerships: action.partnerships,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_COMMUNES:
            return updateCommunes(state, action);
        case actionTypes.UPDATE_ECONOMIC_SECTORS:
            return updateEconomicSectors(state, action);
        case actionTypes.UPDATE_COMPANY_TYPES:
            return updateCompanyTypes(state, action);
        case actionTypes.UPDATE_PARTNERSHIPS:
            return updatePartnerships(state, action);
        default:
            return state;
    }
};

export default reducer;
