import * as actionTypes from "../actionTypes";

import { updateState } from "../utils";

const initialState = {
    totalData: null,
    companies: null,
    persons: null,
    activities: null,
};

const updateTotalData = (state, action) => {
    return updateState(state, {
        totalData: action.totalData,
    });
};

const updateCompanies = (state, action) => {
    return updateState(state, {
        companies: action.companies,
    });
};

const updatePersons = (state, action) => {
    return updateState(state, {
        persons: action.persons,
    });
};

const updateActivities = (state, action) => {
    return updateState(state, {
        activities: action.activities,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_DASHBOARD_TOTAL_DATA:
            return updateTotalData(state, action);
        case actionTypes.UPDATE_DASHBOARD_COMPANIES:
            return updateCompanies(state, action);
        case actionTypes.UPDATE_DASHBOARD_PERSONS:
            return updatePersons(state, action);
        case actionTypes.UPDATE_DASHBOARD_ACTIVITIES:
            return updateActivities(state, action);
        default:
            return state;
    }
};

export default reducer;
