import React, { useEffect } from "react";
import Loadable from "react-loadable";
import { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ConfigProvider, notification } from "antd";
import { Loader } from "./components/base";

import { antdTheme, styledTheme } from "./utils/themes";
import { updateMessage } from "./services/store/actions/theme";

import es from "antd/locale/es_ES";

const loading = () => (
    <>
        <Loader />
    </>
);

const Login = Loadable({
    loader: () => import("./pages/Auth/Login/Login"),
    loading,
});

const Register = Loadable({
    loader: () => import("./pages/Auth/Register/Register"),
    loading,
});

const Verify = Loadable({
    loader: () => import("./pages/Auth/Verify/Verify"),
    loading,
});

const ForgetPassword = Loadable({
    loader: () => import("./pages/Auth/ForgetPassword/ForgetPassword"),
    loading,
});

const MainLayout = Loadable({
    loader: () => import("./layouts/MainLayout/MainLayout"),
    loading,
});

const App = () => {
    const dispatch = useDispatch();
    const [api, contextHolder] = notification.useNotification();

    const { message } = useSelector((state) => state.theme);

    useEffect(() => {
        if (message.content !== null) {
            api[message.type]({
                message: message.type.toUpperCase(),
                description: message.content,
                duration: 15,
                onClose: () => dispatch(updateMessage(null, null)),
            });
        }
    }, [message.content]); // eslint-disable-line

    return (
        <ConfigProvider theme={antdTheme} locale={es}>
            {contextHolder}

            <ThemeProvider theme={styledTheme}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/verify" element={<Verify />} />
                        <Route path="/forget-password" element={<ForgetPassword />} />
                        <Route path="/*" element={<MainLayout />} />
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </ConfigProvider>
    );
};

export default App;
