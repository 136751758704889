import * as actionTypes from "../actionTypes";
import { updateState } from "../utils";

const initialState = {
    partnerships: null,
};

const updatePartnerships = (state, action) => {
    return updateState(state, {
        partnerships: action.partnerships,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_PARTNERSHIPS:
            return updatePartnerships(state, action);
        default:
            return state;
    }
};

export default reducer;
