import * as actionTypes from "../actionTypes";

import { updateState } from "../utils";

const initialState = {
    allPersons: null,
    persons: null,
    total: 0,
    filter: {
        page: 1,
        searchKey: "",
        gender: null,
        sortKey: "created_at",
        sortDirection: "desc",
    },
    interests: null,
};

const updateAllPersons = (state, action) => {
    return updateState(state, {
        allPersons: action.allPersons,
    });
};

const updatePersons = (state, action) => {
    return updateState(state, {
        persons: action.persons,
        total: action.total,
    });
};

const updateFilter = (state, action) => {
    return updateState(state, {
        filter: action.filter,
    });
};

const updateInterests = (state, action) => {
    return updateState(state, {
        interests: action.interests,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ALL_PERSONS:
            return updateAllPersons(state, action);
        case actionTypes.UPDATE_PERSONS:
            return updatePersons(state, action);
        case actionTypes.UPDATE_PERSON_FILTER:
            return updateFilter(state, action);
        case actionTypes.UPDATE_INTERESTS:
            return updateInterests(state, action);
        default:
            return state;
    }
};

export default reducer;
