import { theme } from "antd";

import { colorBlack, colorDark, colorError, colorGray, colorPrimary, colorSuccess, colorWhite } from "./constants";

const antdTheme = {
    algorithm: theme.defaultAlgorithm,
    token: {
        colorPrimary: colorPrimary,
        colorLink: colorPrimary,
        colorBgLayout: colorWhite,
        colorTextBase: colorDark,
        colorText: colorDark,

        borderRadius: 8,

        controlHeight: 36,

        fontFamily: "Roboto",
    },
};

const styledTheme = {
    colorPrimary: colorPrimary,
    colorRed: colorError,
    colorOrange: colorPrimary,
    colorPurple: colorSuccess,

    colorWhite: colorWhite,
    colorGray: colorGray,
    colorDark: colorDark,
    colorBlack: colorBlack,

    antdBorderColor: "#d9d9d9",
    antdInputHoverBorderColor: "#79a1a6",
    antdInputFocusBoxShadow: "rgb(55 5 255 / 6%)",
    antdInputColor: "rgba(0, 0, 0, 0.88)",

    // Component colors
    authBgColor: "#FFF",
    authLeftBgColor: "#13276D",
    siderBgColor: colorGray,
    siderMenuActiveColor: colorPrimary,
    siderMenuTextColor: "#5a5a5a",
};

export { antdTheme, styledTheme };
