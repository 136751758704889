import axios from "axios";

import * as actionTypes from "../actionTypes";
import { errorManageHandler, updateLoading } from "./theme";

import { API_BASE_URL, LS_AUTH_TOKEN_KEY } from "../../../utils/constants";

export const updateToken = (token, keepToken = false) => {
    if (keepToken) {
        localStorage.setItem(LS_AUTH_TOKEN_KEY, token);
    }

    return {
        type: actionTypes.UPDATE_TOKEN,
        token,
    };
};

export const updateSocketToken = (socketToken) => {
    return {
        type: actionTypes.UPDATE_SOCKET_TOKEN,
        socketToken,
    };
};

const updateUserData = (userData) => {
    return {
        type: actionTypes.UPDATE_USER_DATA,
        userData,
    };
};

export const fetchUserData = () => {
    return (dispatch, getState) => {
        const token = getState().auth.token;
        if (!token) {
            dispatch(logout());
        } else {
            axios
                .get(`${API_BASE_URL}/auth/profile/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res) => {
                    const userData = res.data;
                    dispatch(updateUserData(userData));
                })
                .catch((e) => {
                    dispatch(errorManageHandler(e));
                });
        }
    };
};

export const updateProfile = (data) => {
    return (dispatch, getState) => {
        dispatch(updateLoading(true));
        const token = getState().auth.token;
        axios
            .put(`${API_BASE_URL}/auth/profile/`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                const userData = res.data;
                dispatch(updateUserData(userData));
                dispatch(updateLoading(false));
            })
            .catch((e) => {
                dispatch(errorManageHandler(e));
            });
    };
};

export const logout = () => {
    return async () => {
        localStorage.removeItem(LS_AUTH_TOKEN_KEY);
        window.open(`/login?next=${window.location.pathname}`, "_parent");
    };
};
